<template>
  <v-dialog v-model="dialog" max-width="1200">
    <v-card :loading="loading">
      <v-card-title>
        جزئیات سفارش "{{ selectedData.code ? selectedData.code : "" }}"
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <!-- <v-col class="d-flex child-flex" cols="12">
            <v-form
              ref="form_status"
              @submit.prevent="setStatus"
              v-if="isAdmin"
            >
              <div class="d-flex ">
                <v-select
                  v-model="status"
                  :items="statuses"
                  item-value="id"
                  item-text="value"
                  hide-details="auto"
                  outlined
                  label="وضعیت"
                  style="flex: 1"
                />
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  class="my-auto mr-2"
                  large
                >
                  ثبت وضعیت
                </v-btn>
              </div>
            </v-form>
          </v-col> -->
          <v-col cols="12" sm="3">
            <small>کد سفارش</small>
            <h3 class="black--text">{{ selectedData.code }}</h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.status">
            <small>وضعیت</small>
            <h3 class="black--text">
              {{ selectedData.formatted_status }}
            </h3>
          </v-col>

          <v-col cols="12" sm="3" v-if="selectedData.payment_id">
            <small>شناسه پرداخت</small>
            <h3 class="black--text">{{ selectedData.payment_id }}</h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.created_at">
            <small>زمان ایجاد سفارش</small>
            <h3 class="black--text">
              {{ selectedData.created_at | moment("jYYYY/jMM/jDD ساعت HH:mm") }}
            </h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.payment_id">
            <small>زمان پرداخت</small>
            <h3 class="black--text">
              {{
                selectedData.payment.updated_at
                  | moment("jYYYY/jMM/jDD ساعت HH:mm")
              }}
            </h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.payment_id">
            <small>مبلغ پرداخت شده</small>
            <h3 class="black--text">
              {{
                selectedData.payment.price
                  ? new Intl.NumberFormat().format(selectedData.payment.price)
                  : "-"
              }}
              تومان
            </h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.discount">
            <small>کد تخفیف</small>
            <h3 class="black--text">
              {{ selectedData.discount.code }}
            </h3>
          </v-col>
          <v-col cols="12" sm="3" v-if="selectedData.user">
            <small>توسط</small>
            <h3 class="black--text">
              {{
                selectedData.user.first_name + " " + selectedData.user.last_name
              }}
            </h3>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <h3 class="mt-5">آیتم های این سفارش</h3>
        <v-row v-if="selectedData.order_product">
          <v-col
            class="d-flex child-flex"
            cols="12"
            lg="3"
            md="4"
            sm="6"
            v-for="(order, i) in selectedData.order_product"
            :key="i"
          >
            <product-list-box
              v-if="order.product"
              :productId="order.id"
              :slug="order.product.slug"
              :img="
                order.product && order.product.media && order.product.media.url
                  ? order.product.media.url
                  : require('@/assets/img/man.jpg')
              "
              :title="order.product.name"
              :price="
                getUnixDifferenceTimeFromNow(order.product.end_offer_date) > 0
                  ? order.product.offer_price || order.product.price || 0
                  : order.product.price || 0
              "
              :withRemove="false"
            />
            <product-list-box
              v-else-if="order.course"
              :productId="order.id"
              :img="
                order.course && order.course.media && order.course.media.url
                  ? order.course.media.url
                  : null
              "
              :title="order.course.title"
              :price="
                getUnixDifferenceTimeFromNow(order.course.end_offer_date) > 0
                  ? order.course.offer_price || order.course.price || 0
                  : order.course.price || 0
              "
              :withRemove="false"
            />
            <product-list-box
              v-else-if="order.scenario"
              :productId="order.id"
              :title="order.scenario.title"
              :price="order.price"
              :withRemove="false"
            />
          </v-col>
        </v-row>
        <h3 class="mt-5" v-if="selectedData.desc">توضیحات</h3>
        <h4 class="mt-4" v-if="selectedData.desc">{{ selectedData.desc }}</h4>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductListBox from "../Pages/ProductListBox.vue";
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import { getUnixDifferenceTimeFromNow } from "@/constants/TimeFunctions";

export default {
  components: { ProductListBox },
  data() {
    return {
      dialog: false,
      loading: false,
      status: 0,
      statuses: Constants.ordersStatus.map((val, i) => ({ id: i, value: val })),
      payments: Constants.paymentsStatus,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedData(val) {
      this.status = val.status;
    },
  },
  methods: {
    getUnixDifferenceTimeFromNow,
    setStatus() {
      if (!this.loading) this.loading = true;
      MyAxios.post(`/orders/${this.selectedData.id}/update`, {
        status: this.status,
      })
        .then((response) => {
          this.$root.$emit("toast", {
            text: "وضعیت با موفقیت تغییر کرد.",
            type: "success",
          });
          this.$emit("GetList");
          this.loading = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
          });
        });
    },
  },
};
</script>

<style></style>
